<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
// import element1 from "@/views/element1.vue";
// import pdfIndex from "@/views/pdfViews/pdfIndex.vue"

export default {
  created() {
    // let p = this.$axios.get("http://localhost:3000/data.json", {
    //   params: { limit: 10 },
    // });
    // p.then((res) => {
    //   console.log(res.data);
    // });
    // p.catch((err) => {
    //   console.log(err);
    // });
  },
  name: "App",
  data() {
    return {
      goodsTitle: "fuzhujian",
    };
  },
  methods: {
    submit1() {
      // this.$store.commit('changeVal',this.goodsTitle)
      this.$store.dispatch("change", this.goodsTitle);
    },
  },
  components: {
    // element1
    // pdfIndex
  },
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
</style>
