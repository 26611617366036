import Vue from 'vue'
import VueRouter from 'vue-router'
import Test from '../views/Test.vue'

Vue.use(VueRouter)

//定义路由,每个路由应该映射一个组件,routes这个名字是固定的不能改
const routes = [
    // {
    //     path:'/',
    //     redirect:'/login'
    // },
    {
        path: '/login',
        name: 'login',
        component: () => import('@/views/pdfViews/login')
    },
    {
        path: '/',
        component: () => import('@/views/pdfViews/pdfIndex')
    },
    {
        path: '/test',
        name: 'Test',
        component: Test //第一种引用组件的方式
    },
    {
        path: '/test2',
        name: 'Test2',
        component: () => import('@/views/Test2'), //第二种引用组件的方式，@代表src目录
        children: [ //二级路由
            {
                path: '/test2/test3',
                name: 'Test3',
                component: () => import('@/views/Test3')
            }
        ]
    },
    {
        path: '/test4/:id',//动态路由，地址栏传参数
        name: 'Test4',
        component: () => import('@/views/Test4')
    },
]

const router = new VueRouter(
    {
        mode: 'history',
        base: process.env.BASE_URL,
        routes
    }
)

//挂载路由导航守卫
router.beforeEach((to, from, next) => {
    // to 将要访问的路径
    // from 从哪个路由跳转而来
    // next 是一个函数，表示放行
    // next() 表示放行; next('/login') 表示强制跳转到/login

    if (to.path === '/login') return next();

    //获取token
    var token = window.localStorage.getItem("myToken");

    if (!token) {
        return next('login')
    } else {
        return next();
    }
})

export default router