const userstore = {
    state: {
        name: 'tom'
    },
    mutations: {
        changeName(state, name) {
            state.name = name
        }
    }
}

export default userstore