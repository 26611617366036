import Vue from 'vue'
import Vuex from 'vuex'
import userstore from '@/store/userStore.js'

Vue.use(Vuex)

export default new Vuex.Store({

    state: {
        // 用于存储数据
        value1: 'hello111',
        value2: 'hello222'
    },
    mutations: {
        //类似于methods函数
        changeValue(state, val) {
            state.value1 = val
        }
    },
    actions: {
        change(store, val) {
            //store.state.value2 = val; //一般不这样写，一般用下面这种写法
            store.commit('changeValue', val)
        }
    },
    getters: {
        //相当于计算属性
        length() {
            return 10
        }
    },
    modules: {
        user: userstore //modules用来拆分state,引入其他文件
    }
})